import { default as _47app_47pages_47configs_46vueMeta } from "/app/pages/configs.vue?macro=true";
import { default as _47app_47pages_47domains_46vueMeta } from "/app/pages/domains.vue?macro=true";
import { default as _47app_47pages_47editor_47_91id_93_46vueMeta } from "/app/pages/editor/[id].vue?macro=true";
import { default as _47app_47pages_47index_46vueMeta } from "/app/pages/index.vue?macro=true";
import { default as _47app_47pages_47login_46vueMeta } from "/app/pages/login.vue?macro=true";
import { default as _47app_47pages_47logout_46vueMeta } from "/app/pages/logout.vue?macro=true";
import { default as _47app_47pages_47users_46vueMeta } from "/app/pages/users.vue?macro=true";
export default [
  {
    name: _47app_47pages_47configs_46vueMeta?.name ?? "configs",
    path: _47app_47pages_47configs_46vueMeta?.path ?? "/configs",
    file: "/app/pages/configs.vue",
    children: [],
    meta: _47app_47pages_47configs_46vueMeta,
    alias: _47app_47pages_47configs_46vueMeta?.alias || [],
    redirect: _47app_47pages_47configs_46vueMeta?.redirect || undefined,
    component: () => import("/app/pages/configs.vue").then(m => m.default || m)
  },
  {
    name: _47app_47pages_47domains_46vueMeta?.name ?? "domains",
    path: _47app_47pages_47domains_46vueMeta?.path ?? "/domains",
    file: "/app/pages/domains.vue",
    children: [],
    meta: _47app_47pages_47domains_46vueMeta,
    alias: _47app_47pages_47domains_46vueMeta?.alias || [],
    redirect: _47app_47pages_47domains_46vueMeta?.redirect || undefined,
    component: () => import("/app/pages/domains.vue").then(m => m.default || m)
  },
  {
    name: _47app_47pages_47editor_47_91id_93_46vueMeta?.name ?? "editor-id",
    path: _47app_47pages_47editor_47_91id_93_46vueMeta?.path ?? "/editor/:id",
    file: "/app/pages/editor/[id].vue",
    children: [],
    meta: _47app_47pages_47editor_47_91id_93_46vueMeta,
    alias: _47app_47pages_47editor_47_91id_93_46vueMeta?.alias || [],
    redirect: _47app_47pages_47editor_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/app/pages/editor/[id].vue").then(m => m.default || m)
  },
  {
    name: _47app_47pages_47index_46vueMeta?.name ?? "index",
    path: _47app_47pages_47index_46vueMeta?.path ?? "/",
    file: "/app/pages/index.vue",
    children: [],
    meta: _47app_47pages_47index_46vueMeta,
    alias: _47app_47pages_47index_46vueMeta?.alias || [],
    redirect: _47app_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _47app_47pages_47login_46vueMeta?.name ?? "login",
    path: _47app_47pages_47login_46vueMeta?.path ?? "/login",
    file: "/app/pages/login.vue",
    children: [],
    meta: _47app_47pages_47login_46vueMeta,
    alias: _47app_47pages_47login_46vueMeta?.alias || [],
    redirect: _47app_47pages_47login_46vueMeta?.redirect || undefined,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _47app_47pages_47logout_46vueMeta?.name ?? "logout",
    path: _47app_47pages_47logout_46vueMeta?.path ?? "/logout",
    file: "/app/pages/logout.vue",
    children: [],
    meta: _47app_47pages_47logout_46vueMeta,
    alias: _47app_47pages_47logout_46vueMeta?.alias || [],
    redirect: _47app_47pages_47logout_46vueMeta?.redirect || undefined,
    component: () => import("/app/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: _47app_47pages_47users_46vueMeta?.name ?? "users",
    path: _47app_47pages_47users_46vueMeta?.path ?? "/users",
    file: "/app/pages/users.vue",
    children: [],
    meta: _47app_47pages_47users_46vueMeta,
    alias: _47app_47pages_47users_46vueMeta?.alias || [],
    redirect: _47app_47pages_47users_46vueMeta?.redirect || undefined,
    component: () => import("/app/pages/users.vue").then(m => m.default || m)
  }
]