import axios from 'axios'

export default defineNuxtPlugin(() => {
  return {provide: {whoAmI: async (force = false) => {
    try {
      if (!process.client) {
        console.log(process.client)
        return false
      }
      let data = window.localStorage.getItem('whoAmI')
      if (data && !force) {
        data = JSON.parse(data)
        if (data.expires > Date.now()) {
          return data
        }
      }
      let response = await axios.get('/api/auth/whoami')
      data = response.data
      data.expires = Date.now() + 1000 * 60 * 16
      delete data.error
      window.localStorage.setItem('whoAmI', JSON.stringify(data))
      return data
    } catch (e) {
      if (!e.response || e.response.status !== 401) console.error(e)
      return false
    }
  }}}
})
