export default defineNuxtRouteMiddleware(async (to) => {
    const whoAmI:any = await useNuxtApp().$whoAmI()
    const publicPages = ['/login', '/logout']
    if (!whoAmI && !publicPages.includes(to.path)) {
        return navigateTo('/login')
    } else if (whoAmI && publicPages.filter((i) => i !== '/logout').includes(to.path)) {
        return navigateTo('/')
    } else if (whoAmI && to.path.startsWith('/users')) {
        if (!whoAmI.admin) return navigateTo('/')
    }
})
