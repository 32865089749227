import * as Sentry from '@sentry/vue'
import { BrowserTracing } from "@sentry/tracing";
import { useRouter } from "#app";

export default defineNuxtPlugin((nuxtApp) => {
  console.log('Initializing error reporting...')
  console.log('Thank you for helping us improve s.Cookies!')
  Sentry.init({
    Vue: nuxtApp.vueApp,
    dsn: "https://ab874108787b4ba2be14f0910e4d96bb@glitch.scolasti.co/2",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(useRouter()),
      }),
    ],
  })
})
