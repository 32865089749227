import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import node_modules__pnpm_nuxt_643_0_0_eslint_648_30_0_rollup_642_79_1_sass_641_57_0_node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_g2dgVvYHGS from "/app/node_modules/.pnpm/nuxt@3.0.0_eslint@8.30.0_rollup@2.79.1_sass@1.57.0/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules__pnpm_nuxt_643_0_0_eslint_648_30_0_rollup_642_79_1_sass_641_57_0_node_modules_nuxt_dist_pages_runtime_router_mjs_3Afy8ja0MW from "/app/node_modules/.pnpm/nuxt@3.0.0_eslint@8.30.0_rollup@2.79.1_sass@1.57.0/node_modules/nuxt/dist/pages/runtime/router.mjs";
import plugins_axios_js_sVCuMR6hEC from "/app/plugins/axios.js";
import plugins_sentry_js_PZVGm6LH3G from "/app/plugins/sentry.js";
import plugins_toast_js_Zl2rZYKMaV from "/app/plugins/toast.js";
import plugins_vuetify_js_8NhHJigKc1 from "/app/plugins/vuetify.js";
import plugins_whoami_js_VpHUXIcUdy from "/app/plugins/whoami.js";
export default [
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules__pnpm_nuxt_643_0_0_eslint_648_30_0_rollup_642_79_1_sass_641_57_0_node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_g2dgVvYHGS,
  node_modules__pnpm_nuxt_643_0_0_eslint_648_30_0_rollup_642_79_1_sass_641_57_0_node_modules_nuxt_dist_pages_runtime_router_mjs_3Afy8ja0MW,
  plugins_axios_js_sVCuMR6hEC,
  plugins_sentry_js_PZVGm6LH3G,
  plugins_toast_js_Zl2rZYKMaV,
  plugins_vuetify_js_8NhHJigKc1,
  plugins_whoami_js_VpHUXIcUdy
]